import logo from "./logo.svg";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

Auth.currentAuthenticatedUser({
  bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
})
  .then((user) => console.log(user.attributes["nickname"]))
  .catch((err) => console.log(err));

async function updateUser() {
  let user = await Auth.currentAuthenticatedUser();

  console.log("User:  ", user);
  let result = Auth.updateUserAttributes(user, {
    nickname: "newname",
  });
  console.log("Result:  ", result);
  return result;
}

async function getUser() {
  let user = await Auth.currentAuthenticatedUser();
  let result = user.attributes["family_name"];
  alert(result);
}

function App({ signOut, user }) {
  return (
    <div className="App">
      <header className="App-header"></header>

      <div class="topnav" id="myTopnav">
        <a href="#home" class="active">
          Home
        </a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
        <div class="dropdown">
          <button class="dropbtn">
            Profile
            <i class="fa fa-caret-down"></i>
          </button>

          <div class="dropdown-content">
            <button class="btn" onClick={updateUser}>
              Update Nickname
            </button>
            <br />
            <button class="btn" onClick={getUser}>
              Get Last Name
            </button>
            <br />
            <button class="btn" onClick={signOut}>
              Log Out
            </button>
          </div>
        </div>
        {/* <div class="dropdown">
          <button class="dropbtn" onClick={signOut}>
            Log Out
            <i class="fa fa-caret-down"></i>
          </button>
        </div> */}
      </div>
      <div id="home" />
      <br />
      <br />
      <br />
      <h1>Hey, {user.attributes.name}!</h1>
      <img src={logo} className="App-logo" alt="logo" />
      <h1>
        All that work to sign up, and there's nothing to see here!
        <br></br>
        We're still working on it. Check back soon!
      </h1>
      <div id="contact"></div>
    </div>
  );
}

export default withAuthenticator(App, {
  // socialProviders: ["apple", "facebook", "google", "amazon"],
});
